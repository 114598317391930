<template>
  <div class="tips">
    <div>打开河小象大语文APP失败</div>
    <div>即将进入<a class="link" href="itms-apps://itunes.apple.com/cn/app/id1481527553?mt=8">App Store</a> {{count}} ...</div>
  </div>
</template>

<script>
export default {
  data: () => ({
    count: 3
  }),
  mounted() {
    const timer = setInterval(() => {
      this.count--
      if (this.count <= 0) {
        try {
          location.href = 'itms-apps://itunes.apple.com/cn/app/id1481527553?mt=8'
        } catch (error) {

        }
        clearInterval(timer)
      }
    }, 1000);
  }
}
</script>

<style scoped>
.tips {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.link {
  color: #FF7041;
}
</style>
